<script setup lang="ts">
import { Button, ComponentSize, ComponentType } from '@/entities';
import { mapAttributes, mapBackground } from '@/utils';
import { computed } from 'vue';
import { IconVue } from '.';

const props = defineProps<{
    button: Button;
}>();
// defineEmits<{
//     (e: 'button-clicked'):void;
// }>();
const tag = computed(() => {
    var tag = props.button.tag ?? "div";
    if (props.button.url)
        tag = "a"
    return tag;
})
const target = computed(() => {
    if (props.button.url && props.button.target) {
        return props.button.target
    }
    else
        return "_self"
})
const type = computed(() => {
    return props.button.type == ComponentType.Secondary ? "c-button--secondary" : props.button.type == ComponentType.Tertiary ? "c-button--tertiary" : ""
})
const size = computed(() => {
    return props.button.size == ComponentSize.Small ? "c-button--small" : "";
})
const classes = computed(() => {
    const classes = props.button.classes ?? "";
    return `${classes ?? ""} ${type.value ?? ""} ${size.value ?? ""} ${mapBackground(props.button.scheme)}`
})
const iconBefore = computed(() => {
    var newIconBefore = props.button.iconBefore!;
    newIconBefore.classes = "c-button__icon";
    return newIconBefore;
})
const iconAfter = computed(() => {
    var newIconAfter = props.button.iconAfter!;
    newIconAfter.classes = "c-button__icon";
    return newIconAfter;
})
</script>
<template>
    <tag :class="`c-button ${classes}`" v-bind="mapAttributes(props.button.attributes, props.button.url, target)">
        <IconVue v-if="button.iconBefore" :icon="iconBefore" />
        <span class="c-button__text">
            {{ button.text }}
        </span>
        <IconVue v-if="button.iconAfter" :icon="iconAfter" />
    </tag>
</template>