import PrimeVue from "primevue/config";
import { CLUB_PLOC, EVENT_PLOC, MEMBERSHIP_PLOC, REGISTRATION_PLOC } from "@/utils";
import { configureApi, dependenciesLocator } from "neos-core";
import { App } from "vue";

export * as Components from "./components/indexClient";

export const configureApp = (app: App) => {
	app.use(PrimeVue, {
		locale: {
			dayNames: ["zondag", "maandag", "dinsdag", "woensdag", "donderdag", "vrijdag", "zaterdag"],
			dayNamesShort: ["zo", "ma", "di", "wo", "do", "vr", "za"],
			dayNamesMin: ["zo", "ma", "di", "wo", "do", "vr", "za"],
			monthNames: ["januari", "februari", "maart", "april", "mei", "juni", "juli", "augustus", "september", "oktober", "november", "december"],
			monthNamesShort: ["jan.", "feb.", "mrt.", "apr.", "mei", "jun.", "jul.", "aug.", "sep.", "okt.", "nov.", "dec."],
			today: 'Vandaag',
			weekHeader: 'Wk',
			firstDayOfWeek: 1,
			dateFormat: 'dd/mm/yy',
			clear: "Wissen",	
		}
	}); /*.use(router)*/
	app.provide(EVENT_PLOC, dependenciesLocator.provideEventPloc());
	app.provide(CLUB_PLOC, dependenciesLocator.provideClubPloc());
	app.provide(REGISTRATION_PLOC, dependenciesLocator.provideActivityRegistrationPloc());
	app.provide(MEMBERSHIP_PLOC, dependenciesLocator.provideMemberShipPloc());
};
const BASE_URL = String(import.meta.env.VITE_BASE_URL);

configureApi(BASE_URL, {
	"Content-Type": "application/json",
	Accept: "application/json",
	"Access-Control-Allow-Origin": "*",
	"Access-Control-Allow-Methods": "GET, HEAD, POST, PUT, DELETE, OPTIONS",
	"Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept, Z-Key",
});
export * as Vue from "vue";
export { renderToString } from "vue/server-renderer";
