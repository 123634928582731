<script setup lang="ts">
import { ComponentSize, Link } from '@/entities';
import { mapBackground } from '@/utils';
import { computed } from 'vue';
import { IconButtonVue, IconVue } from '.';

const props = defineProps<{
    link: Link
}>();

const tag = computed(() => {
    return props.link.tag ? props.link.tag : "div"
})
const size = computed(() => {
    return props.link.size == ComponentSize.Small ? "c-link--small" : "";
})
const defaultClass = computed(() => {
    return props.link.defaultClass ? props.link.defaultClass : "c-link"
})
const iconButtonBefore = computed(() => {
    if (props.link.iconButtonBefore) {
        var iconButtonBefore = props.link.iconButtonBefore;
        iconButtonBefore.classes?.concat(` ${defaultClass.value}__icon-button`)
        iconButtonBefore.size = ComponentSize.ExtraSmall;
        return iconButtonBefore
    }

    return props.link.iconButtonBefore;
})
const iconBefore = computed(() => {
    if (props.link.iconBefore) {
        var iconBefore = props.link.iconBefore;
        iconBefore.classes?.concat(` ${defaultClass.value}__icon`)
        return iconBefore;
    }
    return props.link.iconBefore;
})
const iconButtonAfter = computed(() => {
    if (props.link.iconButtonAfter) {
        var iconButtonAfter = props.link.iconButtonAfter;
        iconButtonAfter.classes?.concat(` ${defaultClass.value}__icon-button`)
        return iconButtonAfter
    }

    return props.link.iconButtonAfter;
})
const iconAfter = computed(() => {
    if (props.link.iconAfter) {
        var iconAfter = props.link.iconAfter;
        iconAfter.classes?.concat(` ${defaultClass.value}__icon`)
        return iconAfter;
    }
    return props.link.iconAfter;
})
const classes = computed(() => {
    return props.link.classes ? props.link.classes : "";
})
</script>
<template>
    <tag :class="`${defaultClass} ${classes} ${mapBackground(link.scheme)} ${size}`">
        <IconButtonVue v-if="iconButtonBefore" :icon-button="iconButtonBefore" />
        <IconVue v-if="iconBefore && !link.iconButtonBefore && !link.iconButtonAfter" :icon="iconBefore" />
        <a :href="link.url">
            <span :class="defaultClass + '__text'">
                {{ link.text }}
            </span>
        </a>
        <IconVue v-if="iconAfter && !link.iconButtonBefore && !link.iconButtonAfter" :icon="iconAfter" />
        <IconButtonVue v-if="iconButtonAfter" :icon-button="iconButtonAfter" />
    </tag>
</template>