<script setup lang="ts">
import { ComponentPosition, Link, SectionTitle } from '@/entities';
import { computed } from 'vue';
import { ButtonVue, LinkVue, TagVue } from '.';

const props = defineProps<{
    sectionTitle: SectionTitle;
}>();

const titleTag = computed(() => {
    return props.sectionTitle.titleTag ? props.sectionTitle.titleTag : "h2";
})

const center = computed(() => {
    return props.sectionTitle.position == ComponentPosition.Center ? "c-section-title--center" : "";
})

const classes = computed(() => {
    return props.sectionTitle.classes ? `${props.sectionTitle.classes} ${center}` : "";
})
const attributes = computed(() => {
    return props.sectionTitle.attributes ?? "";
})

function addClassToLink(link: Link) {
    var newLink = link;
    newLink.classes = `${link.classes} c-section-title__link`
    return newLink;
}
</script>
<template>
    <div :class="`c-section-title ${classes ?? ''}`" v-bind="attributes">
        <div class="c-section-title__header">
            <div v-if="sectionTitle.tagline" class="c-section-title__tagline">
                {{ sectionTitle.tagline }}
            </div>
            <titleTag v-if="sectionTitle.title" class="c-section-title__title">
                {{ sectionTitle.title }}
            </titleTag>
        </div>
        <div v-if="sectionTitle.text" class="c-section-title__wysiwyg s-wysiwyg">
            {{ sectionTitle.text }}
        </div>
        <div v-if="sectionTitle.links && sectionTitle.links.length > 0" v-for="link in sectionTitle.links">
            <LinkVue :link="addClassToLink(link)" />
        </div>
        <div v-if="sectionTitle.buttons && sectionTitle.buttons.length > 0" v-for="button in sectionTitle.buttons">
            <ButtonVue :button="button" />
        </div>
        <div v-if="sectionTitle.tags && sectionTitle.tags.length > 0" v-for="tag in sectionTitle.tags">
            <TagVue :tag="tag" />
        </div>
    </div>
</template>