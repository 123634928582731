<script setup lang="ts">
import { ComponentSize, ComponentType, IconButton } from '@/entities';
import { computed } from 'vue';
import { IconVue } from '.';
import { mapAttributes, mapBackground } from '@/utils';

const props = defineProps<{
    iconButton: IconButton

}>();
const tag = computed(() => {
    if (props.iconButton.url) {
        return "a"
    }
    return props.iconButton.tag ? props.iconButton.tag : "div"
})
const size = computed(() => {
    return props.iconButton.size == ComponentSize.ExtraSmall ? "c-icon-button--extra-small" : "";
})
const type = computed(() => {
    return props.iconButton.type == ComponentType.Secondary ? "c-icon-button--secondary" : "";
})
const classes = computed(() => {
    return props.iconButton.classes ? props.iconButton.classes : "";
})

</script>
<template>
    <tag :class="`c-icon-button ${classes} ${size} ${type} ${mapBackground(iconButton.scheme)}`"
        v-bind="mapAttributes(props.iconButton.attributes, props.iconButton.url)">
        <IconVue v-if="iconButton.icon && iconButton.icon.src"
            :icon="{ size: iconButton.size, classes: 'c-icon-button__icon', src: iconButton.icon?.src }" />

        <IconVue v-else
            :icon="{ size: iconButton.size, classes: 'c-icon-button__icon', src: '/assets/icons/arrow-right.svg' }" />

        <span v-if="iconButton.label" class="sr-only">{{ iconButton.label }}</span>
    </tag>
</template>