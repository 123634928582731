<script setup lang="ts">
import { ComponentSize, Icon, SelectOption } from "@/entities";
import { IconVue } from "../common";
import { ref } from "vue"
defineProps<{
	placeholder: string
	options: SelectOption[]
	modelValue: string
}>();
const selected = ref('');
const emits = defineEmits<{
	(e: 'update:modelValue', value: string): void
}>();
const iconSelect: Icon = { size: ComponentSize.Small, classes: "c-select__icon", src: "/assets/icons/caret-down.svg" }
</script>
<template>
	<div class="c-select c-input" style="padding:0;">
		<select name="id" id="id" class="c-select__field" required v-model="selected" @change="emits('update:modelValue', selected)">
			<option value="">{{ placeholder }}</option>
			<template v-for="option in options">
				<option :value="option.value">
					{{ option.label }}
				</option>
			</template>
		</select>

		<IconVue :icon="iconSelect" />
	</div>
</template>