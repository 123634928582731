import { ComponentScheme } from "../entities";

export function mapBackground(scheme: ComponentScheme) {
	switch (scheme) {
		case ComponentScheme.Default:
			return "scheme-default";
		case ComponentScheme.Neutral:
			return "scheme-neutral";
		case ComponentScheme.Yellow:
			return "scheme-yellow";
		case ComponentScheme.Orange:
			return "scheme-orange";
		case ComponentScheme.Pink:
			return "scheme-pink";
		case ComponentScheme.Blue:
			return "scheme-blue";
		case ComponentScheme.Green:
			return "scheme-green";
		case ComponentScheme.Turquoise:
			return "scheme-turquoise";
		case ComponentScheme.Purple:
			return "scheme-purple";
		case ComponentScheme.Red:
			return "scheme-red";
		default:
			return "";
	}
}
