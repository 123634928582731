<script setup lang="ts">
import { Button, ComponentScheme, ComponentSize, ComponentType } from '@/entities';
import { ButtonVue } from '../common';

const props = defineProps<{
    title: string;
    body: string;
    buttonBackText: string;
    buttonProgrammeText: string;
    totalAmount:number;
    clubName:string;
}>();
const backButton: Button = {
    scheme: ComponentScheme.Default,
    size: ComponentSize.Default,
    type: ComponentType.Secondary,
    text: props.buttonBackText,
    tag: "button"
};
const programmeButton: Button = {
    scheme: ComponentScheme.Default,
    size: ComponentSize.Default,
    type: ComponentType.Primary,
    text: props.buttonProgrammeText,
    tag: "button"
};
</script>
<template>
    <div>
        <div>
            {{ title }}
        </div>
        <div>
            {{ body }}
        </div>
        <div>
            <ButtonVue :button="backButton" />
            <ButtonVue :button="programmeButton" />
        </div>
    </div>
</template>