<script setup lang="ts">
import { Button, ComponentScheme, ComponentSize, ComponentType, StepRegistrationFormula } from "@/entities";
import { ButtonVue } from "../common";
import { ref } from "vue";
import { Translations } from "@/entities/ActivityTranslations";
interface Prices {
	priceForMembers: number;
	priceForNonMembers: number;
}
interface PricePerFormula {
	id: string;
	title: string;
	price: Prices;
}
interface MoreDays {
	id: string;
	date: string;
}

defineProps<{
	isActive: boolean;
	formulas: PricePerFormula[];
	days: MoreDays[];
	translations: Translations;
	errorMessage: string | undefined;
}>();
const emits = defineEmits<{
	(e: "clicked-next", formData: StepRegistrationFormula): void;
	(e: "clicked-input", formula: PricePerFormula): void;
}>();
const select = ref();
const buttonNext: Button = {
	scheme: ComponentScheme.Default,
	size: ComponentSize.Default,
	type: ComponentType.Primary,
	text: "Volgende",
	tag: "button",
};
const form: StepRegistrationFormula = {
	checked: false,
	id: "0",
	price: 0,
};

function clickNext() {
	if (select.value.id) {
		form.id = select.value.id;
		if (select.value.price.priceForNonMembers != null) {
			form.price = select.value.price.priceForMembers;
		} else {
			form.price = select.value.price.priceForNonMembers;
		}
		emits("clicked-next", form);
	}
}
function click() {
	emits("clicked-input", select.value);
}
</script>
<template>
	<li class="c-step">
		<div class="c-step__title">{{ translations.formule }}</div>

		<form class="c-step__container" @submit.prevent="clickNext" v-if="isActive" autocomplete="off">
			<div class="c-step__container-item">
				<div class="c-checkbox-group">
					<div class="c-checkbox-group__items">
						<div class="c-checkbox-group__item" v-for="item in formulas">
							<input
								class="c-checkbox-group__input"
								type="radio"
								v-model="select"
								:id="item.id"
								:name="item.id"
								:value="item"
								v-on:change="click"
							/>
							<label class="c-checkbox-group__label" :for="item.id">
								<span class="c-checkbox-group__icon"></span>

								<span>
									{{ item.title }}
									<span
										v-if="item.price.priceForNonMembers == 0 && item.price.priceForMembers != 0"
										style="font-size: 0.9rem"
									>
										(Leden: €{{ item.price.priceForMembers }} | Niet-leden: €{{
											item.price.priceForMembers
										}})</span
									>
									<span
										v-else
										style="font-size: 0.9rem"
									>
										(Leden: €{{ item.price.priceForMembers }} | Niet-leden: €{{
											item.price.priceForNonMembers
										}})</span
									>
								</span>
							</label>
						</div>
					</div>
				</div>
			</div>
			<div class="c-step__buttons">
				<ButtonVue :button="buttonNext" @click="clickNext" />
			</div>
			<p v-if="errorMessage" class="c-errorMessage">{{ errorMessage }}</p>
		</form>
	</li>
</template>
