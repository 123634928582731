<script setup lang="ts">
import { Button, ButtonInput, Card, ComponentPosition, ComponentScheme, ComponentSize, ComponentType, Link, SectionTitle } from "@/entities";
import { ButtonInputVue, ButtonVue, CardVue, LinkVue, SectionTitleVue } from "../common"
import { Ref, inject, onMounted, ref, } from "vue";
import { ClubItem, ClubRequest } from "neos-core";
import { CLUB_PLOC, usePlocState } from "@/utils";
import SearchMap from "../map/SearchMap.vue";

const props = defineProps<{
    imgSrc: string;
    sectionTitle?: string;
    sectionText?: string;
    pageSize: number;
    placeHolderSearch:string;
    listViewTextSearch:string;
    mapViewTextSearch:string;
    listViewText:string;
    mapViewText:string;
    loadMoreButtonText:string;
    searchButtonText:string;
    linkToWebsiteText:string;
    searchMapDictionary:string;
    mapDictionary:string;
}>();

const clubPloc = inject(CLUB_PLOC)!;
const clubState = ref(usePlocState(clubPloc));

const clubRequest: Ref<ClubRequest> = ref({
    search: "",
    amount: props.pageSize ?? 12
})

const breadCrumbsAttributes: Record<string, string> = {};
breadCrumbsAttributes["data-aos"] = "fade-up";
const buttonInputAttributes: Record<string, string> = {};
buttonInputAttributes["type"] = "submit";

const clubItems: Ref<ClubItem[]> = ref([]);
const clubMapitems: Ref<ClubItem[]> = ref([]);


const showLoadMore = ref(false);
const showMap = ref(true);

const sectionTitle: SectionTitle = {
    title: props.sectionTitle,
    text: props.sectionText,
    position: ComponentPosition.Left
}

const buttonInput: ButtonInput = {
    input: {
        id: "id",
        name: "name",
        type: "search",
        placeholder: props.placeHolderSearch,
    },
    button: {
        url: "#",
        text: props.searchButtonText,
        scheme: ComponentScheme.Blue,
        size: ComponentSize.Default,
        type: ComponentType.Primary,
        iconAfter: {
            size: ComponentSize.Default,
            src: "/assets/icons/magnifying-glass.svg"
        },
        attributes: buttonInputAttributes
    }
}

const linkList: Link = {
    scheme: ComponentScheme.Green,
    size: ComponentSize.Default,
    classes: "c-link--underline b-search__link b-search__list-link is-active",
    text: props.listViewTextSearch,
    iconAfter: {
        size: ComponentSize.Small,
        src: "/assets/icons/list-bullets.svg"
    }
}
const linkListResult: Link = {
    scheme: ComponentScheme.Blue,
    size: ComponentSize.Default,
    classes: "c-link--underline b-search__link b-search__list-link is-active",
    text: props.listViewText,
    iconAfter: {
        size: ComponentSize.Small,
        src: "/assets/icons/list-bullets.svg"
    }
}
const linkMap: Link = {
    scheme: ComponentScheme.Green,
    size: ComponentSize.Default,
    classes: "c-link--underline b-search__link b-search__map-link",
    text: props.mapViewTextSearch,
    iconAfter: {
        size: ComponentSize.Small,
        src: "/assets/icons/map-trifold.svg"
    }
}
const linkMapResult: Link = {
    scheme: ComponentScheme.Blue,
    size: ComponentSize.Default,
    classes: "c-link--underline b-search__link b-search__map-link",
    text: props.mapViewText,
    iconAfter: {
        size: ComponentSize.Small,
        src: "/assets/icons/map-trifold.svg"
    }
}

const loadMoreButton: Button = {
    scheme: ComponentScheme.Blue,
    size: ComponentSize.Default,
    type: ComponentType.Secondary,
    text: props.loadMoreButtonText,
}
function loadClubs() {
    clubPloc.GetClubItems(clubRequest.value).then(() => {
        if (clubState.value.kind == "LoadedState") {
            clubItems.value = clubState.value.data as ClubItem[];
            if (clubState.value.pagination) {
                showLoadMore.value = clubItems.value.length < clubState.value.pagination!.totalAmount;
            }
        } else if (clubState.value.kind == "ErrorState") {
            clubItems.value = [];
            console.log("Error: " + clubState.value.error)
        }
    })
}
function loadClubsMaps() {
    clubPloc.GetClubMapItems(clubRequest.value).then(() => {
        if (clubState.value.kind == "LoadedState") {
            clubMapitems.value = clubState.value.data as ClubItem[];

        } else if (clubState.value.kind == "ErrorState") {
            clubMapitems.value = [];
        }
    })
}


function createClubCard(club: ClubItem) {
    var linkToWebsite: Link = {
        scheme: ComponentScheme.Blue,
        size: ComponentSize.Default,
        text: props.linkToWebsiteText,
        url: club.url,
        target: "_blank"
    }
    var card: Card = {
        title: club.name,
        link: linkToWebsite
    }
    return card;
}

function searchClubs(value: string) {
    clubRequest.value.search = value;
    loadClubs();
}

function loadMoreClubs() {
    clubRequest.value.amount += props.pageSize;
    loadClubs();
}

function clickSwitch() {
    showMap.value = !showMap.value;
}

function onZoom(){
    console.log("zoomed");
}

onMounted(() => {
    loadClubs()
    loadClubsMaps()
})
</script>
<template>
    
    <div class="b-search js-search">
        <!-- <HeroVue :bread-crumbs="breadCrumbs" :title="'Vind een Neos-club'" /> -->
        <div class="grid grid-cols-12 relative">
            <picture
                class="c-image c-image--cover col-span-12 gap-y-10 vp7:absolute w-full h-full -z-50 shape-tr-lg vp7:shape-br-lg">
                <img :src="props.imgSrc" alt="" class="c-image__item ls-is-cached lazyloaded">
            </picture>
            <div
                class="col-span-12 vp7:col-span-8 vp12:col-span-6 vp7:shape-tr bg-green-20 p-4 px-6 py-8 vp7:px-12 vp7:py-14 vp7:mt-40 grid gap-y-4">

                <SectionTitleVue v-if="sectionTitle" :section-title="sectionTitle" />
                <ButtonInputVue :button-input="buttonInput" @button-clicked="(value: string) => searchClubs(value)"
                    @value-changed="(value: string) => searchClubs(value)" />
                <LinkVue :link="linkList" @click="clickSwitch" />
                <LinkVue :link="linkMap" @click="clickSwitch" />
            </div>
        </div>
        <!-- Search results -->
        <div class="section-md grid gap-y-8">
            <div class="flex">
                <h3 class="b-search__title grow">
                    {{props.searchMapDictionary}}
                </h3>
                <LinkVue :link="linkListResult" @click="clickSwitch" />
                <LinkVue :link="linkMapResult" @click="clickSwitch" />
            </div>
            <!-- Map view -->
            <div class="b-search__map is-active">
                <SearchMap :clubs="clubMapitems" :linkToWebsiteText="linkToWebsiteText" :on-clicked="onZoom"/>
                <!-- Map -->
            </div>
            <!-- List view -->
            <div class="b-search__list grid-cols-1 vp7:grid-cols-2 vp9:grid-cols-3 gap-4">
                <template v-for="result in clubItems">
                    <CardVue :card="createClubCard(result)" />
                </template>
            </div>
            <div style="display: flex; justify-content: center;">
                <ButtonVue style="width: max-content;" class="b-search__button" v-if="showLoadMore && !showMap"
                    :button="loadMoreButton" @click="loadMoreClubs()" />
            </div>
        </div>
    </div>
</template>
