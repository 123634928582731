export enum ComponentScheme {
	Default = 0,
	Neutral = 1,
	Yellow = 2,
	Orange = 3,
	Pink = 4,
	Blue = 5,
	Green = 6,
	Turquoise = 7,
	Purple = 8,
	Red = 9,
}
