<script setup lang="ts">
import type { Tag } from "@/entities";
import { mapBackground } from "@/utils";
import { computed } from "vue";
const props = defineProps<{
    tag: Tag
}>();

const tag = computed(() => {
    return props.tag.url ? 'a' : "div"
})
const attributes = computed(() => {
    let attributes: Record<string, string> = {};
    if (props.tag.attributes) {
        attributes = props.tag.attributes
    }

    if (props.tag.url) {
        attributes["href"] = props.tag.url;
    }
    return attributes
})
</script>
<template>
    <tag :class="`c-tag ${props.tag.classes ?? ''} ${mapBackground(props.tag.scheme)} `" v-bind="attributes">
        {{ props.tag.text }}
    </tag>
</template>