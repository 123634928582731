<script setup lang="ts">
import { Button, ComponentScheme, ComponentSize, ComponentType, step2Form } from "@/entities"
import { ButtonVue } from "../common";
const props = defineProps<{
    isActive: boolean
    errorMessage: string | undefined
    isLoading: boolean
}>()

const emits = defineEmits<{
    (e: 'clicked-previous'): void;
    (e: 'clicked-next', formData: step2Form): void;

}>()
const buttonPrevious: Button = {
    scheme: ComponentScheme.Default, size: ComponentSize.Default, type: ComponentType.Secondary, text: "Vorige"
}
const buttonLoading: Button = {
	scheme: ComponentScheme.Default,
	size: ComponentSize.Default,
	type: ComponentType.Primary,
	iconAfter: {
		size: ComponentSize.Default,
		src: "/assets/icons/loading.svg",
	},
	classes: "disabled",
	tag: "button",
};
const buttonNext: Button = {
    scheme: ComponentScheme.Default, size: ComponentSize.Default, type: ComponentType.Primary, text: "Volgende", tag: "button"
}
const form :step2Form = {
    address: "",
    houseNr: "",
    bus: "",
    city: "",
    postalCode: '',
    conditions: false,
    reminder: false,
}

function clickPrevious() {
    emits("clicked-previous")
}
function clickNext() {
    emits('clicked-next', form)
}
</script>
<template>
    <li class="c-step ">
        <div class="c-step__title">2. Adresgegevens</div>

        <form class="c-step__container" @submit.prevent="clickNext" v-if="isActive" autocomplete="off">
            <div class="c-step__container-item">

                <div class="c-step__container-info">
                    <div class="c-input-group">
                        <label for="address" class="c-label">
                            Adres
                        </label>

                        <input v-model="form.address" class="c-input" type="text" id="address" name="address"
                            placeholder="bvb. Schoolstraat" required>
                    </div>

                    <div class="c-input-group">
                        <label for="houseNr" class="c-label">
                            Huisnummer
                        </label>

                        <input v-model="form.houseNr" class="c-input" type="text" id="houseNr" name="houseNr"
                            placeholder="bvb. 16" required>
                    </div>

                    <div class="c-input-group">
                        <label for="bus" class="c-label">
                            Bus
                            <span class="c-label__optional">
                                (optioneel)
                            </span>
                        </label>

                        <input v-model="form.bus" class="c-input" type="text" id="bus" name="bus"
                            placeholder="bvb. 301">
                    </div>
                    <div class="c-input-group">
                        <label for="city" class="c-label">
                            Woonplaats
                        </label>

                        <input v-model="form.city" class="c-input" type="text" id="city" name="city"
                            placeholder="bvb. Roesbeek" required>
                    </div>
                    <div class="c-input-group">
                        <label for="postalCode" class="c-label">
                            Postcode
                        </label>

                        <input v-model="form.postalCode" class="c-input" type="text" id="postalCode" name="postalCode" placeholder="bvb. 2050" required>
                    </div>
                </div>
                <div class="c-checkbox-group">
                    <div class="c-checkbox-group__items">
                        <div class="c-checkbox-group__item">
                            <input v-model="form.conditions" class="c-checkbox-group__input" type="checkbox" id="conditions"  
                                name="conditions" required>
                            <label class="c-checkbox-group__label" for="conditions">
                                <span class="c-checkbox-group__icon"></span>

                                <span>
                                    Ik ga akkoord met de algemene voorwaarden en de privacy verklaring van Neos.
                                </span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="c-step__buttons">
                <ButtonVue :button="buttonPrevious" @click="clickPrevious" />
                <ButtonVue v-if="props.isLoading" :button="buttonLoading" disabled />
                <ButtonVue v-else :button="buttonNext" type="submit"/>
            </div>
            <p v-if="errorMessage" class="c-errorMessage">{{ errorMessage }}</p>
        </form>
    </li>
</template>