<script setup lang="ts">
import { Ref, inject, ref } from "vue";
import { StepFormulaVue, StepUserFieldVue } from "../step";
import { REGISTRATION_PLOC, usePlocState } from "@/utils";
import { ActivityRegistrationRequest } from "neos-core";
import { MemberSignupForm, StepRegistrationFormula } from "@/entities/RegistrationSteps";
import { Translations } from "@/entities/ActivityTranslations";

interface Prices {
	priceForMembers: number;
	priceForNonMembers: number;
}
interface PricePerFormula {
	id: string;
	title: string;
	price: Prices;
}
interface MoreDays {
	id: string;
	date: string;
}

const props = defineProps<{
	clubId: string;
	activityId: string;
	formulas: PricePerFormula[];
	moreDays: MoreDays[];
	translations: Translations;
	activityWaitlistUrl: string;
	helpNeededText: string;
	activityName: string;
}>();
const amount = ref(0);
const formulaTitle = ref("");
const isLoading = ref(false);
const activityRegistrationPloc = inject(REGISTRATION_PLOC)!;
const registrationState = ref(usePlocState(activityRegistrationPloc));
const isStep1Active = ref(true);
const isStep2Active = ref(false);
const totalAmount = ref(0);
const registrationRequest: ActivityRegistrationRequest = {} as ActivityRegistrationRequest;
const showMemberPrice = ref(false);
const currentPricePerFormula = ref<PricePerFormula>();
const errorMessage: Ref<string | undefined> = ref();
const MemberForm: Ref<MemberSignupForm> = ref({} as MemberSignupForm);

function setMemberPersonalInfo(formData: MemberSignupForm) {
	isLoading.value = true;
	registrationRequest.members = [];
	formData.members.forEach((member) => {
		if (member.isMember) {
			registrationRequest.members.push({
				birthDate: new Date().toISOString().split("T")[0],
				email: "",
				firstName: "",
				isMember: true,
				lastName: "",
				memberNumber: member.memberNumber,
			});
		} else {
			registrationRequest.members.push({
				birthDate: formatDate(member.birthDate),
				email: member.email,
				firstName: member.firstName,
				isMember: false,
				lastName: member.lastName,
				memberNumber: "",
			});
		}
	});
	registrationRequest.activityId = props.activityId;
	requestMembership();
}

function setRegistrationFormula(formData: StepRegistrationFormula) {
	registrationRequest.formulaId = formData.id;
	registrationRequest.clubId = props.clubId;
	isStep1Active.value = false;
	isStep2Active.value = true;
}

function formatDate(date: string) {
	var dateSegments = date.split("/");
	return new Date(`${dateSegments[2]}-${dateSegments[1]}-${dateSegments[0]}`).toISOString().split("T")[0];
}
function clickedPrevious() {
	isStep2Active.value = false;
	isStep1Active.value = true;
}
function updatePrice(price: PricePerFormula) {
	currentPricePerFormula.value = price;
	if (showMemberPrice.value || price.price.priceForNonMembers == null || price.price.priceForNonMembers <= 0) {
		amount.value = price.price.priceForMembers;
	} else {
		amount.value = price.price.priceForNonMembers;
	}
	formulaTitle.value = price.title;
}
function requestMembership() {
	activityRegistrationPloc.RequestRegistration(registrationRequest).then(() => {
		if (registrationState.value.kind == "LoadedState") {
			if (registrationState.value.data.redirectUrl != null) {
				window.location.replace(registrationState.value.data.redirectUrl);
			} else {
				window.location.replace(props.activityWaitlistUrl);
			}
			isLoading.value = false;
		} else if (registrationState.value.kind == "ErrorState") {
			if (registrationState.value.error != null) {
				errorMessage.value = registrationState.value.error.message;
			}
			isLoading.value = false;
		}
	});
}
function handleMemberNumberUpdate(formData: MemberSignupForm) {
	MemberForm.value = formData;
	totalAmount.value = 0;
	for (const member of MemberForm.value.members) {
		if (member.isMember) {
			totalAmount.value += currentPricePerFormula.value?.price.priceForMembers || 0;
		} else {
			totalAmount.value += currentPricePerFormula.value?.price.priceForNonMembers || 0;
		}
	}
}
</script>
<template>
	<div class="flex flex-col vp7:flex-row gap-4 justify-between section-b-md">
		<div class="grid gap-y-4 vp7:max-w-[560px] order-2 vp7:order-1">
			<StepFormulaVue
				:is-active="isStep1Active"
				:formulas="formulas"
				:days="moreDays"
				:error-message="errorMessage"
				:translations="translations"
				@clicked-input="(payload) => updatePrice(payload)"
				@clicked-next="(payload) => setRegistrationFormula(payload)"
			/>
			<StepUserFieldVue
				:is-active="isStep2Active"
				:translations="translations"
				:error-message="errorMessage"
				:is-loading="isLoading"
				@input-membernumber="(payload) => handleMemberNumberUpdate(payload)"
				@clicked-previous="clickedPrevious"
				@clicked-next="(formData) => setMemberPersonalInfo(formData)"
			/>
		</div>

		<div class="vp7:max-w-[420px] order-1 vp7:order-2">
			<div class="flex flex-col gap-6 bg-grey-05 shape-tr-sm px-8 py-6">
				<h3 class="typo-h4">{{ translations.overzicht }}</h3>
				<div>
					<div class="typo-strong">{{ activityName }}</div>
					<div class="border-b border-grey-100 py-1"></div>
					<div v-for="member in MemberForm.members" class="grid grid-cols-4 py-1">
						<div class="col-span-3">
							{{ member.isMember ? member.memberNumber : `${member.firstName} ${member.lastName}` }}
						</div>
						<div class="col-span-1 text-end">
							€{{
								member.isMember
									? currentPricePerFormula?.price.priceForMembers
									: currentPricePerFormula?.price.priceForNonMembers
							}}
						</div>
					</div>
					<div class="grid grid-cols-4 py-1">
						<div class="col-span-3 typo-strong">{{ translations.totaal }}</div>
						<div class="col-span-1 typo-strong text-end">€{{ totalAmount }}</div>
					</div>
				</div>
			</div>
			<div v-if="helpNeededText" class="s-wysiwyg px-6 py-4 border border-grey-20 mt-4 vp7:mt-6">
				<b>{{ translations.hulpNodig }}</b> {{ helpNeededText }}
			</div>
		</div>
	</div>
</template>
