<script setup lang="ts">
import { Link } from '@/entities';
import { BreadCrumbsVue } from '.';

defineProps<{
    breadCrumbs: Link[],
    title: string,
}>();
const fadeUpAttribute: Record<string, string> = {};
fadeUpAttribute["data-aos"] = "fade-up";

</script>
<template>
    <div class="scheme-default full-bleed layout bound-x grid-rows-1 container-grid">
        <div class="row-start-1 row-end-1 flex flex-col gap-y-4 py-10 vp7:py-16">
            <BreadCrumbsVue :bread-crumbs="{ attributes: fadeUpAttribute, links: breadCrumbs }" />
            <div class="c-section-title aos-init aos-animate" v-bind="fadeUpAttribute">
                <div class="c-section-title__header">
                    <h2 class="c-section-title__title">{{ title }}</h2>
                </div>
            </div>
        </div>
    </div>
</template>