<script setup lang="ts">
import { ComponentSize, Icon } from '@/entities';
import { computed } from 'vue';

const props = defineProps<{
    icon: Icon;
}>();
const size = computed(() => {
    switch (props.icon.size) {
        case ComponentSize.Small:
            return "c-icon--small";
        case ComponentSize.ExtraSmall:
            return "c-icon--small";
        case ComponentSize.Large:
            return "c-icon--large";
        default:
            return "";
    }
})

const attributes = computed(() => {
    return props.icon.attributes ? props.icon.attributes : "";
})
</script>
<template>
    <span :class="`c-icon ${icon.classes} ${size}`" :style="`--icon: url(${icon.src})`" v-bind="attributes">

    </span>
</template>