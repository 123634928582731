<script setup lang="ts">
import { ButtonInput } from '@/entities';
import { computed, ref } from 'vue';
import { ButtonVue } from '.';

const props = defineProps<{
    buttonInput: ButtonInput;
}>();

defineEmits<{
    (e: 'button-clicked', value: string): void;
    (e: "value-changed", value: string): void
}>();

const value = ref("");

const attributes = computed(() => {
    return props.buttonInput.attributes ?? "";
})
const type = computed(() => {
    return props.buttonInput.input?.type ?? "text";
})
const id = computed(() => {
    return props.buttonInput.input?.id ?? "unique-id";
})
const name = computed(() => {
    return props.buttonInput.input?.name ?? "name";
})
const placeholder = computed(() => {
    return props.buttonInput.input?.placeholder ?? "Placeholder";
})
</script>
<template>
    <div v-bind="attributes" :class="`c-button-input ${buttonInput.classes ?? ''}`">
        <input class="c-button-input__input c-input" :type="type" :name="name" :id="id" :placeholder="placeholder" required
            v-model="value" @keyup.enter="$emit('value-changed', value)">
        <ButtonVue :button="buttonInput.button" @click.prevent="$emit('button-clicked', value)" />
    </div>
</template>