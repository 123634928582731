<script setup lang="ts">
import { BreadCrumbs } from '@/entities/BreadCrumbs';
import { computed } from 'vue';
import { LinkVue } from '.';
import { ComponentScheme, ComponentSize } from '@/entities';

const props = defineProps<{
    breadCrumbs: BreadCrumbs;
}>();

const attributes = computed(() => {
    return props.breadCrumbs.attributes ? props.breadCrumbs.attributes : '';
})

</script>
<template>
    <nav v-if="props.breadCrumbs.links && props.breadCrumbs.links.length > 1"
        :class="`c-breadcrumbs ${props.breadCrumbs.classes}`" v-bind="attributes">
        <ul class="c-breadcrumbs__list">
            <li v-for="(link, index) in props.breadCrumbs.links" class="c-breadcrumbs__item">
                <template v-if="index+1 != props.breadCrumbs.links.length">
                    <LinkVue :link="{
                        scheme: ComponentScheme.Blue,
                        size: ComponentSize.Default,
                        classes: 'c-breadcrumbs__text c-breadcrumbs__link',
                        url: link?.url,
                        text: link?.text,
                        attributes: link?.attributes,
                        target: link?.target
                    }" />
                    <span>/</span>
                </template>
                <span v-else class="c-breadcrumbs__text is-current">
                    {{ link.text }}
                </span>
            </li>

        </ul>
    </nav>
</template>