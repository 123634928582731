<script setup lang="ts">
import { Card, ComponentScheme, ComponentSize, Link } from "@/entities";
import  { LMap, LTileLayer, LPopup, LMarker } from "@vue-leaflet/vue-leaflet";
// import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { ClubItem } from "neos-core";
import {  onMounted, ref } from "vue";
import { CardVue } from "../common";
// import { LatLng } from "leaflet";
const props = defineProps<{
    clubs: ClubItem[];
    linkToWebsiteText:string;
}>();
const emits = defineEmits<{
    (e: 'clicked'): void;
}>();
const map = ref<L.Map>();
// var map = L.map('map').setView([51.505, -0.09], 13);

function createClubCard(club: ClubItem) {
    var linkToWebsite: Link = {
        scheme: ComponentScheme.Blue,
        size: ComponentSize.Default,
        text: props.linkToWebsiteText,
        url: club.url,
        target: "_blank"
    }
    var card: Card = {
        title: club.name,
        link: linkToWebsite
    }
    return card;
}
const zoom = ref(10);
onMounted(() =>{
    console.log(map.value);
    console.log(map.value!.getBounds);
    // var latlng = new LatLng(50.5, 30.5);

    // (<L.Map>map.value).flyTo(latlng)
    // var bounds = (<L.Map>map.value);
    //     console.log(bounds)
    //     var boundszoom = (<L.Map>map.value).getBoundsZoom
    //         console.log(boundszoom)
})
</script>
<template>
    
    <div style="height:600px; width:100%" >
        <l-map ref="map" v-model:zoom="zoom" :center="[51.0543422, 3.7174243]" :use-global-leaflet="false" @click="emits('clicked')">
            <l-tile-layer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" layer-type="base"
                name="OpenStreetMap"></l-tile-layer>
                <l-marker v-for="club in clubs" :lat-lng="[club.latitude, club.longitude]">
                    <l-popup> 
                        <CardVue :card="createClubCard(club)" /> 
                    </l-popup>
                </l-marker>
        </l-map>
    </div>
</template>
<style>
.leaflet-popup-content-wrapper {
	border-radius: 0;
}
	.leaflet-popup-content-wrapper.leaflet-popup-content.c-card-default{
        border: none;
	}
	.leaflet-container a.leaflet-popup-close-button {
		display: none;
	}
    </style>