<script setup lang="ts">
import { ComponentScheme, ComponentSize, ComponentType, Icon, Pagination } from '@/entities';
import { computed, onMounted, ref } from 'vue';
import { IconButtonVue } from '.';


const props = defineProps<{
    pagination: Pagination;
    maxNumberOfPageButtons: number;
}>();
const emits = defineEmits<{
    (e: 'page-changed', page: number): void;
}>();

const currentPage = ref(1);

const startingPage = computed(() => {
    var startingPage = currentPage.value - props.maxNumberOfPageButtons / 2;
    if (startingPage < 1)
        startingPage = 1;

    return startingPage
})

const endPage = computed(() => {
    var endPage = startingPage.value + props.maxNumberOfPageButtons - 1;
    if (endPage > props.pagination.amountOfPages)
        endPage = props.pagination.amountOfPages;

    return endPage;
})

const attributes = computed(() => {
    return props.pagination.attributes ? props.pagination.attributes : "";
})

const iconLeft: Icon = {
    size: ComponentSize.Default,
    src: "/assets/icons/arrow-left.svg"
}
const iconRight: Icon = {
    size: ComponentSize.Default,
    src: "/assets/icons/arrow-right.svg"
}

function anchoredPageUrl(page: number) {
    return `${props.pagination.pageUrl ?? ''}?${props.pagination.queryParam ?? ''}=${page}#${props.pagination.anchor ?? ''}`;
}
function changePage(page: number) {
    currentPage.value = page;
    emits('page-changed', page);
}

onMounted(() => {
    currentPage.value = props.pagination.page;
})
</script>
<template >
    <div v-if="pagination.amountOfPages > 1" :class="`c-pagination ${pagination.classes}`" v-bind="attributes">
        <IconButtonVue v-if="currentPage > 1" @click.prevent="changePage(currentPage - 1)" :icon-button="{
            scheme: ComponentScheme.Blue,
            size: ComponentSize.Default,
            type: ComponentType.Primary,
            url: anchoredPageUrl(currentPage - 1),
            icon: iconLeft
        }" />

        <IconButtonVue v-else :icon-button="{
            scheme: ComponentScheme.Blue,
            size: ComponentSize.Default,
            type: ComponentType.Primary,
            icon: iconLeft,
            classes: 'disabled'
        }" />

        <div class="c-pagination__items">
            <template v-if="pagination.amountOfPages > 6 && currentPage + 1 >= maxNumberOfPageButtons">
                <a @click.prevent="changePage(1)" :href="anchoredPageUrl(1)"
                    class="c-pagination__item c-pagination__item--last">1</a>
                <span class="c-pagination__dott"
                    v-if="pagination.amountOfPages > 6 && currentPage + 1 != maxNumberOfPageButtons">...</span>
            </template>
            <template v-for="i in endPage">
                <a v-if="i >= startingPage" @click.prevent="changePage(i)" :href="anchoredPageUrl(i)"
                    :class="`c-pagination__item ${currentPage == i ? 'is-active' : ''}`">{{ i }}</a>

            </template>
            <template v-if="pagination.amountOfPages > 6 && endPage < pagination.amountOfPages">
                <span class="c-pagination__dott"
                    v-if="pagination.amountOfPages > 6 && currentPage + maxNumberOfPageButtons/2 != pagination.amountOfPages">...</span>
                <a @click.prevent="changePage(pagination.amountOfPages)" :href="anchoredPageUrl(pagination.amountOfPages)"
                    class="c-pagination__item c-pagination__item--last">{{
                        props.pagination.amountOfPages }}</a>
            </template>
        </div>

        <IconButtonVue v-if="pagination.amountOfPages > currentPage" @click.prevent="changePage(currentPage + 1)"
            :icon-button="{
                scheme: ComponentScheme.Blue,
                size: ComponentSize.Default,
                type: ComponentType.Primary,
                url: anchoredPageUrl(currentPage + 1),
                icon: iconRight
            }" />

        <IconButtonVue v-else :icon-button="{
            scheme: ComponentScheme.Blue,
            size: ComponentSize.Default,
            type: ComponentType.Primary,
            icon: iconRight,
            classes: 'disabled'
        }" />

    </div>
</template>