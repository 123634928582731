<script setup lang="ts">
import { ref } from "vue";
import { Variant } from "@/entities";

const props = defineProps<{
	id: string;
	options: Variant[];
	title: string;
	text: string;
	isOpen: boolean;
}>();
const url = ref('')

const emits = defineEmits<{
	(e: "closed", payload: { isClosed: boolean }): void;
}>();
const close = () => {
	emits("closed", { isClosed: false });
};
</script>
<template>
	<div :class="'c-modal js-modal layout layout-bound-x' + (isOpen ? ' is-shown' : '')" data-modal-id="001">
		<div class="c-modal__container">
			<button class="c-modal__icon c-modal__close-trigger" :onclick="close" data-dismiss="modal" aria-label="Open">
				<span class="c-icon" style="--icon: url(/assets/icons/x.svg)"> </span>
			</button>

			<h2 class="c-modal__title">Selecteer een deelnemende club</h2>

			<div class="c-modal__text s-wysiwyg">
				Indien jouw club deelneemt aan dit event, vind je deze hieronder terug. Staat jouw club er niet bij, of
				ben je nog geen lid bij Neos? Neem dan contact op met Neos vzw, welkom@neosvzw.be
			</div>

			<div class="c-modal__select c-input-group">
				<label for="label-id" class="c-label"> Selecteer jouw club </label>

				<div class="c-select">
					<select name="id" id="id" class="c-select__field" v-model="url" required="">
						<option v-for="clubVariant in options" :value="clubVariant.variantUrl">{{clubVariant.clubName}}</option>
					</select>

					<span class="c-icon c-select__icon c-icon--small" style="--icon: url(/assets/icons/caret-down.svg)">
					</span>
				</div>
			</div>

			<div class="c-modal__buttons">
				<div
					class="c-button c-modal__close-trigger scheme-blue c-button--secondary"
					data-dismiss="modal"
					aria-label="Close"
					onclick="close"
				>
					<span class="c-button__text"> Annuleren </span>
				</div>

				<a class="c-button scheme-blue" :href="url" target="_self">
					<span class="c-button__text"> Bevestigen </span>
				</a>
			</div>
		</div>
	</div>
</template>
