export function mapAttributes(attributes?: Record<string, string>, url?: string, target?:string) {
	let records: Record<string, string> = {};
	if (attributes) {
		records = attributes;
	}
	if (url) {
		records["href"] = url;
		records["target"] = target ?? "_self";
	}
	return records;
}
